@font-face {
  font-family: 'joystix';
  src:
    local('joystix'),
    url('./Assets/joystix-monospace.otf') format('woff');
}

:root {
  --primary: #f0361f;
  --dark-primary: #921103;
  --secondary: #148da6;
  --dark-green: #486837;
  --light-green: #a4c56a;
  --dark: #2c2c2c;
  --white: #fff;
  --black: #000;
  --dark-grey: #707070;
  --grey: #e0e0e0;
  --light-grey: #f3f3f3;
  --bg: #000;
  --text: #7b7b7b;
  --bg-transparent: #00000040;
  --error: #ff0000;
  --success: #84cd7c;
  --mont: Montserrat, sans-serif;
  --max-app-width: 1200px;
  --v-margin: 50px;
  --h-margin: 50px;
  --v-margin-mobile: 10px;
}
* {
  /* font-family: Montserrat, sans-serif; */
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

@media only screen and (max-width: 1100px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 18px;
    font-weight: 900;
  }
  * {
    font-size: 16px;
    color: var(--black);
  }
}
img {
  max-width: 100%;
  max-height: 100%;
}
html {
  scroll-behavior: smooth;
}
body {
  /* font-family: var(--mont); */
  margin: 0;
  padding: 0;
  background-color: var(--dark);
}
/* h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
} */
p {
  font-size: 16px;
  color: var(--black);
}
.App {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: var(--bg);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.centered-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.buttonied,
.buttonied:visited,
.buttonied:hover {
  transition: all 300ms ease;
  background-size: 500%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 2.5em;
  background-image: linear-gradient(to left, var(--dark-green), var(--light-green), var(--dark-green));
  padding: 0 20px;
  border-radius: 6px;
  background-position: 0%;
  color: var(--dark) !important;
  animation: shine 3s linear infinite alternate;
}
@keyframes shine {
  0% {
    background-position: 0%;
  }
  50% {
    background-position: 100%;
  }
  100% {
    background-position: 0%;
  }
}

.download-link {
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
  color: yellow;
}
